<script lang="ts" setup>
import Button from '~/components/ui/button/Button.vue';
import RadioGroup from '~/components/ui/radio-group/RadioGroup.vue';
import RadioGroupItem from '~/components/ui/radio-group/RadioGroupItem.vue';
import Label from '~/components/ui/label/Label.vue';

// EMIT
type Emit = {
    nextTab: [];
};
defineEmits<Emit>();

// VARIABLE
const colorMode = useColorMode();
</script>
<template>
    <div class="flex flex-col items-center justify-between h-full">
        <span>
            <h2 class="text-center text-3xl dark:text-white mb-3">ยินดีต้อนรับสมเสี้ยมสู่</h2>
            <h2 class="text-center text-6xl text-primary-500 dark:text-primary-400 font-bold">SOMSRI BACKEND</h2>
        </span>
        <div class="dark:text-white">
            <p class="text-3xl mb-3">เลือกธีมสีที่ต้องการ</p>

            <div class="flex flex-row justify-center gap-10">
                <RadioGroup v-model="colorMode.preference">
                    <div class="flex items-center gap-3">
                        <RadioGroupItem id="light" class="w-10 h-10" value="light"></RadioGroupItem>
                        <Label class="text-lg" for="light">Light</Label>
                    </div>

                    <div class="flex items-center gap-3">
                        <RadioGroupItem id="dark" class="w-10 h-10" value="dark"></RadioGroupItem>
                        <Label class="text-lg" for="light">Dark</Label>
                    </div>
                </RadioGroup>
            </div>
        </div>
        <Button size="lg" type="button" class="text-lg" @click="$emit('nextTab')"> ต่อไป<Icon name="material-symbols:arrow-right-alt-rounded" size="1.5rem" /> </Button>
    </div>
</template>

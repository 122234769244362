<script setup lang="ts">
import Welcome from '@/components/setup/Section/welcomeTab.vue';
import AlertDialog from '../ui/alert-dialog/AlertDialog.vue';
import AlertDialogContent from '../ui/alert-dialog/AlertDialogContent.vue';
import LoadingSpinner from '../global/LoadingSpinner.vue';

// LAZY COMPONENT
const Finish = defineAsyncComponent(() => import('@/components/setup/Section/finishTab.vue'));
const Password = defineAsyncComponent(() => import('@/components/setup/Section/passwordTab.vue'));
const UserDetails = defineAsyncComponent(() => import('@/components/setup/Section/userDetailsTab.vue'));

// MODEL
const open = defineModel<boolean>('open', { required: true, default: false });

// TYPE
const tab = ref<number>(1);

// FUNCTION
async function completeSetup() {
    window.location.reload();
}

function nextTab() {
    tab.value++;
}

function prevTab() {
    tab.value--;
}
</script>
<template>
    <AlertDialog v-model:open="open">
        <AlertDialogContent class="max-w-4xl h-full max-h-[40rem] overflow-y-auto">
            <Welcome v-if="tab === 1" @next-tab="nextTab()" />
            <Suspense v-if="tab === 2">
                <UserDetails @next-tab="nextTab()" @prev-tab="prevTab()" />
                <template #fallback>
                    <LoadingSpinner :size="30"></LoadingSpinner>
                </template>
            </Suspense>
            <Suspense v-if="tab == 3">
                <Password @prev-tab="prevTab()" @next-tab="nextTab()" />

                <template #fallback>
                    <LoadingSpinner :size="30"></LoadingSpinner>
                </template>
            </Suspense>
            <Suspense v-if="tab == 4">
                <Finish @finish="completeSetup()" />

                <template #fallback>
                    <LoadingSpinner :size="30"></LoadingSpinner>
                </template>
            </Suspense>
        </AlertDialogContent>
    </AlertDialog>
</template>
